.thanks-container {
  width: 100%;
  display: flex;
  overflow: auto;
  align-items: center;
  flex-direction: column;
}
.thanks-thanks {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  flex-direction: column;
  padding-bottom: 0px;
  background-size: cover;
  justify-content: center;
  background-image: url("/background-1500w.jpeg");
}
.thanks-max-content-width-container {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.thanks-card {
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  align-items: center;
  border-radius: 21px;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.thanks-card-content {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.thanks-text {
  font-size: 42px;
  font-style: normal;
  text-align: center;
  font-family: Overpass;
  font-weight: 900;
  line-height: 1.22;
  text-transform: none;
  text-decoration: none;
}
.thanks-icon-container {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.thanks-image {
  width: 150px;
  object-fit: cover;
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.thanks-content-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.thanks-heading {
  font-size: 24px;
  font-style: normal;
  text-align: center;
  font-family: Overpass;
  font-weight: 700;
  line-height: 1.55;
  margin-bottom: var(--dl-space-space-tripleunit);
  text-transform: none;
  text-decoration: none;
}
.thanks-text1 {
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-family: "Playfair Display";
  font-weight: 400;
  line-height: 1.55;
  letter-spacing: 1px;
  text-transform: none;
  text-decoration: none;
}
.thanks-cta-btn {
  color: transparent;
  text-align: center;
  transition: 0.3s;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-700);
  padding-left: var(--dl-space-space-tenunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #f1f1f1;
}
.thanks-cta-btn:hover {
  background-color: var(--dl-color-primary-700);
}
.thanks-text7 {
  color: rgb(16, 16, 16);
}
@media(max-width: 767px) {
  .thanks-card {
    padding: var(--dl-space-space-doubleunit);
  }
  
  .thanks-text {
    font-size: 36px;
  }
  .thanks-heading {
    font-size: 21px;
  }
  .thanks-text1 {
    font-size: 10px;
  }
}
