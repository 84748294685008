.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-section-separator {
  width: 100%;
  height: 2px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-navbar-container {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-navbar {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.home-image {
  width: 120px;
  object-fit: cover;
}
.home-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link {
  transition: 0.3s;
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-link:hover {
  color: var(--dl-color-primary-500);
}
.home-link01 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-link01:hover {
  color: var(--dl-color-primary-500);
}
.home-link02 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-link02:hover {
  color: var(--dl-color-primary-500);
}
.home-link03 {
  transition: 0.3s;
  text-decoration: none;
}
.home-link03:hover {
  color: var(--dl-color-primary-500);
}
.home-icon {
  width: 36px;
  height: 36px;
}
.home-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.home-image1 {
  width: 120px;
  object-fit: cover;
}
.home-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon04 {
  width: 24px;
  height: 24px;
}
.home-links-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-link04 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.home-link04:hover {
  color: var(--dl-color-primary-500);
}
.home-link05 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.home-link05:hover {
  color: var(--dl-color-primary-500);
}
.home-link06 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.home-link06:hover {
  color: var(--dl-color-primary-500);
}
.home-link07 {
  transition: 0.3s;
  text-decoration: none;
}
.home-link07:hover {
  color: var(--dl-color-primary-500);
}
.home-section-separator1 {
  width: 100%;
  height: 2px;
  display: flex;
  margin-top: var(--dl-space-space-tenunits);
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-home {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/background-1500w.jpeg");
}
.home-hero {
  width: 90%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-self: center;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.home-hero-text-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading {
  color: rgb(4, 4, 4);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text03 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-cta-btn-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-cta-btn {
  color: transparent;
  text-align: center;
  transition: 0.3s;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-700);
  padding-left: var(--dl-space-space-tenunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #f1f1f1;
}
.home-cta-btn:hover {
  background-color: var(--dl-color-primary-700);
}
.home-text08 {
  color: rgb(16, 16, 16);
}
.home-image2 {
  width: 330px;
  object-fit: cover;
}
.home-section-separator2 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-services {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-tenunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-heading-container {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text09 {
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text10 {
  text-align: center;
}
.home-cards-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-card {
  width: 31%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.home-image3 {
  width: 100px;
  object-fit: cover;
}
.home-content-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-heading1 {
  text-align: center;
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text11 {
  text-align: center;
}
.home-card1 {
  width: 31%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container1 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.home-image4 {
  width: 100px;
  object-fit: cover;
}
.home-content-container1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-heading2 {
  text-align: center;
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text18 {
  text-align: center;
}
.home-card2 {
  width: 31%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container2 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.home-image5 {
  width: 100px;
  object-fit: cover;
}
.home-content-container2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-heading3 {
  text-align: center;
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text25 {
  text-align: center;
}
.home-section-separator3 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-technologies {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-heading-container1 {
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.home-text32 {
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text33 {
  text-align: center;
}
.home-logo-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-logo {
  width: 90px;
  object-fit: cover;
  margin-right: var(--dl-space-space-fourunits);
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-logo01 {
  width: 90px;
  object-fit: cover;
  margin-right: var(--dl-space-space-fourunits);
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-logo02 {
  width: 90px;
  object-fit: cover;
  margin-right: var(--dl-space-space-fourunits);
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-logo03 {
  width: 90px;
  object-fit: cover;
  margin-right: var(--dl-space-space-fourunits);
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-logo04 {
  width: 85px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-logo-container1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-logo05 {
  width: 90px;
  object-fit: cover;
  margin-right: var(--dl-space-space-fourunits);
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-logo06 {
  width: 90px;
  object-fit: cover;
  margin-right: var(--dl-space-space-fourunits);
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-logo07 {
  width: 90px;
  object-fit: cover;
  margin-right: var(--dl-space-space-fourunits);
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-logo08 {
  width: 90px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-section-separator4 {
  width: 100%;
  height: 2px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-experience {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  flex-direction: column;
  padding-bottom: 0px;
  justify-content: center;
  background-image: linear-gradient(45deg, rgb(215, 159, 194) 3.00%,rgb(111, 197, 233) 99.00%);
}
.home-max-content-width-container {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-heading-container2 {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text34 {
  font-style: normal;
  text-align: center;
}
.home-content-container3 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  justify-content: center;
}
.home-about-1 {
  width: auto;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-self: stretch;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.home-container2 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
.home-icon06 {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text35 {
  text-align: left;
}
.home-container3 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
.home-icon08 {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text43 {
  text-align: left;
}
.home-container4 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
.home-icon10 {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text53 {
  text-align: left;
}
.home-section-separator5 {
  width: 100%;
  height: 2px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-clients {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: Fivenits;
}
.home-heading-container3 {
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.home-text61 {
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text62 {
  text-align: center;
}
.home-logo-container2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-logo09 {
  width: 150px;
  object-fit: cover;
  margin-right: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-logo10 {
  width: 150px;
  object-fit: cover;
  margin-right: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-logo11 {
  width: 150px;
  object-fit: cover;
  margin-right: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-logo12 {
  width: 150px;
  object-fit: cover;
  margin-right: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-logo13 {
  width: 150px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-section-separator6 {
  width: 100%;
  height: 1px;
  display: flex;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-tenunits);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-contact {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-container5 {
  width: 60%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-heading-container4 {
  display: flex;
  align-self: center;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.home-text63 {
  width: 100%;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text64 {
  align-self: center;
  text-align: center;
}
.home-form {
  width: 60%;
  display: flex;
  align-self: center;
  flex-direction: column;
}
.home-textinput {
  width: 100%;
  align-self: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-900);
  border-width: 0.16rem;
  padding-left: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-tripleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-unit);
}
.home-textinput:valid:not(:placeholder-shown):not(:focus) {
  border-color: var(--dl-color-success-500);
}
.home-textinput:invalid:not(:placeholder-shown):not(:focus) + p {
  font-size: 0.96rem;
}
.home-textinput:invalid:not(:placeholder-shown):not(:focus) + p + p {
  font-size: 0px;
}
.home-textinput:invalid:not(:placeholder-shown):not(:focus) {
  border-color: var(--dl-color-danger-500);
}
.email-label {
  font-size: 0px;
  color: var(--dl-color-danger-500); 
  font-style: normal;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.email-divider {
  font-size: 0.96rem;
  color: var(--dl-color-gray-white); 
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
  margin-bottom: var(--dl-space-space-unit);
}
.home-textarea {
  width: 100%;
  align-self: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-900);
  border-width: 0.16rem;
  padding-left: TripleUnit;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: TripleUnit;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-textarea:valid:not(:placeholder-shown):not(:focus) {
  border-color: var(--dl-color-success-500);
}
.home-textarea:invalid:not(:placeholder-shown):not(:focus) + p {
  font-size: 0.96rem;
}
.home-textarea:invalid:not(:placeholder-shown):not(:focus) + p + p {
  font-size: 0px;
}
.home-textarea:invalid:not(:placeholder-shown):not(:focus) {
  border-color: var(--dl-color-danger-500);
}
.idea-label {
  font-size: 0px;
  color: var(--dl-color-danger-500); 
  font-style: normal;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.idea-divider {
  font-size: 0.96rem;
  color: var(--dl-color-gray-white); 
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-button {
  align-self: center;
  text-align: center;
  transition: 0.3s;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tenunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-doubleunit);
}
form:invalid button {
  color: var(--dl-color-gray-500);
  background-color:  var(--dl-color-gray-900);
  border-color: var(--dl-color-gray-800);
}
form:valid button {
  color: var(--dl-color-gray-black);
  background-color: var(--dl-color-primary-700);
  border-color: var(--dl-color-gray-800);
}
.home-container6 {
  width: 35%;
  height: 550px;
  display: flex;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: center;
  margin-left: var(--dl-space-space-tripleunit);
  border-color: var(--dl-color-gray-700);
  border-style: solid;
  border-width: 2px;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-tripleunit);
  background-size: cover;
  justify-content: center;
  background-image: url("/background-1500w.jpeg");
  background-position: center;
}
.home-image6 {
  width: 35%;
  object-fit: cover;
}
.home-section-separator7 {
  width: 100%;
  height: 2px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-footer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #0e0e0e;
}
.home-footer1 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.home-social-links {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link08 {
  display: contents;
}
.home-icon12 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
  margin-top: auto;
  transition: 0.3s;
  margin-left: auto;
  margin-right: var(--dl-space-space-doubleunit);
  margin-bottom: auto;
  text-decoration: none;
}
.home-icon12:hover {
  fill: #1aeaff;
}
.home-link09 {
  display: contents;
}
.home-icon14 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
  transition: 0.3s;
  text-decoration: none;
}
.home-icon14:hover {
  fill: #981bff;
}
.home-copyright-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-icon16 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-link10 {
  color: #ffffff;
}
@media(max-width: 991px) {
  .home-navbar {
    position: relative;
  }
  .home-hero {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-hero-text-container {
    width: 80%;
    align-items: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading {
    text-align: center;
  }
  .home-text03 {
    text-align: center;
  }
  .home-image2 {
    width: 100%;
  }
  .home-text09 {
    text-align: center;
  }
  .home-logo-container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .home-logo-container1 {
    flex-wrap: wrap;
    justify-content: center;
  }
  .home-heading-container3 {
    width: 55%;
  }
  .home-text62 {
    width: auto;
  }
  .home-logo-container2 {
    justify-content: center;
  }
  .home-form {
    width: 90%;
  }
  .home-button {
    align-self: center;
  }
  .home-container6 {
    width: 50%;
    height: 420px;
  }
}
@media(max-width: 767px) {
  .home-navbar {
    position: relative;
    justify-content: center;
  }
  .home-links-container {
    display: none;
  }
  .home-cta-btn {
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .home-heading-container {
    width: 100%;
  }
  .home-cards-container {
    align-items: center;
    flex-direction: column;
  }
  .home-card {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-card1 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-card2 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-heading-container1 {
    width: 100%;
  }
  .home-heading-container2 {
    width: 100%;
  }
  .home-content-container3 {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }
  .home-about-1 {
    width: auto;
  }
  .home-heading-container3 {
    width: 100%;
  }
  .home-logo-container2 {
    justify-content: center;
  }
  .home-contact {
    flex-direction: column;
    justify-content: flex-end;
  }
  .home-container5 {
    width: 100%;
    align-items: center;
  }
  .home-heading-container4 {
    width: 100%;
  }
  .home-text63 {
    text-align: center;
  }
  .home-text64 {
    text-align: center;
  }
  .home-text65 {
    text-align: center;
  }
  .home-text66 {
    text-align: center;
  }
  .home-text67 {
    text-align: center;
  }
  .home-form {
    width: 90%;
  }
  .home-textinput {
    width: 100%;
  }
  .home-textinput1 {
    width: 100%;
  }
  .home-textarea {
    width: 100%;
  }
  .home-button {
    width: 80%;
    align-self: center;
  }
}
@media(max-width: 479px) {
  .home-navbar {
    position: relative;
    justify-content: center;
    }
  .home-burger-menu {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-hero-text-container {
    width: 100%;
  }
  .home-card {
    width: 100%;
  }
  .home-card1 {
    width: 100%;
  }
  .home-card2 {
    width: 100%;
  }
  .home-text32 {
    text-align: center;
  }
  .home-logo-container {
    align-items: flex-start;
    justify-content: center;
  }
  .home-logo-container1 {
    align-items: flex-start;
    justify-content: center;
  }
  .home-about-1 {
    width: 100%;
  }
  .home-text61 {
    text-align: center;
  }
  .home-logo-container2 {
    align-items: flex-start;
    justify-content: center;
  }
  .home-container5 {
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }
  .home-text63 {
    text-align: center;
  }
  .home-form {
    width: 90%;
  }
  .home-textinput {
    width: 100%;
  }
  .home-button {
    width: 80%;
    align-self: center;
  }
  .home-container6 {
    width: 90%;
    height: 300px;
  }
}
